import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import Resource from '../../components/Resource';
import { Column, Container, Row } from '../../components/Grid';

const TeacherLedActivities = () => {
  return (
    <Layout title="Teacher-Led Activities">
      <Section>
        <Container fullWidth>
          <Row>
            <h2>Teacher-Led Activities</h2>
            <p>
              These standards-aligned teacher-led activities each explore an
              essential question related to efficient cooling (and heating)
              solutions for people and spaces. Students break out into groups,
              prototyping designs and solutions, and discuss and answer the
              question together.
            </p>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={3}>
              <Resource
                img="edresources-activities-biomimicry-2x.jpg"
                title="Biomimicry"
                titleClassOverride="resource__title__purple resource__title__uppercase"
                audience="Grade 6-8"
                duration="40-60 minutes"
                description="Students discover how engineers today are drawing on inspiration from the natural world. In this activity, natural cooling processes show how we may one day keep cool using the same strategies as members of the animal kingdom."
                actions={[
                  {
                    label: 'Classroom Activity',
                    download:
                      '/pdfs/SustainableFutures-TeacherLedActivity-Biomimicry-V2.0.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                img="edresources-activities-coolenergy-2x.jpg"
                title="Cool Energy"
                titleClassOverride="resource__title__purple resource__title__uppercase"
                audience="Grade 6-8"
                duration="40-60 minutes"
                description="Students are tasked with comparing renewable and nonrenewable energy sources for the purpose of keeping themselves cool. Students will engage in a WebQuest to compare the various energy sources. "
                actions={[
                  {
                    label: 'Classroom Activity',
                    download:
                      '/pdfs/SustainableFutures-TeacherLedActivity-CoolEnergy-V2.0.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                img="edresources-activities-coolcareers-2x.jpg"
                title="Cool Careers"
                titleClassOverride="resource__title__purple resource__title__uppercase"
                audience="Grade 6-8"
                duration="40-60 minutes"
                description="Different careers in cooling (and heating) are presented in a Gallery Walk for students to decide which they think is most interesting and which is most critical to keeping us cool."
                actions={[
                  {
                    label: 'Classroom Activity',
                    download:
                      '/pdfs/SustainableFutures-TeacherLedActivity-CoolCareers-V2.0.pdf',
                  },
                ]}
              />
            </Column>
            <Column size={3}>
              <Resource
                img="edresources-activities-keepitcool-2x.jpg"
                title="Keep It Cool"
                titleClassOverride="resource__title__purple resource__title__uppercase"
                audience="Grade 6-8"
                duration="40-60 minutes"
                description="Students will use their problem-solving skills to design a better lunchbox after learning the difference between thermal insulators and conductors. They will then take what they have learned and extrapolate it into larger cooling applications."
                actions={[
                  {
                    label: 'Classroom Activity',
                    download:
                      '/pdfs/SustainableFutures-TeacherLedActivity-KeepItCool-V2.0.pdf',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default TeacherLedActivities;
